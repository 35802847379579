// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/_index.jsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/_index.jsx");
  import.meta.hot.lastModified = "1738642866248.842";
}
// REMIX HMR END

import { MailOutlined } from '@ant-design/icons';
import { Button, Form, Input, message } from 'antd';
import { useSearchParams, useNavigate } from '@remix-run/react';
import { useState, useEffect } from 'react';
import ApiService from '../services/ApiService';
import { logActivity } from '../utils/userActivity';
export const meta = () => {
  return [{
    title: 'D-rive Admin Dashboard'
  }, {
    name: 'description',
    content: 'Welcome to D-rive Admin Dashboard!'
  }];
};
export default function LandingPage() {
  _s();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const companyId = searchParams.get('companyId');
  const navigate = useNavigate();

  // State to track if user is logged in
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const onFinish = async values => {
    if (!companyId) {
      message.error('Invalid company credentials');
      return;
    }
    try {
      setLoading(true);
      const response = await ApiService.post(`/api/operator/auth`, {
        ...values,
        companyId
      });
      if (response.message) {
        return message.error(response.message);
      }
      if (response?.data) {
        setIsLoggedIn(true); // Set login state
        const activityMessage = `${response?.data} at Login Page`;
        logActivity(activityMessage);
        return navigate(`/?companyId=${companyId}&login=true`);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };
  return <div>
      <div className="pt-3 pb-4">
        <h2 className="text-center">TDG CRM Operation Portal</h2>
      </div>

      <div className="d-flex justify-content-center">
        <div className="card col-md-5 col-sm-10  p-5 mx-3">
          <div className="form-header mb-3">
            <h2 className="text-center">Operator Login</h2>
            <p className="mt-3">
              Please enter your user name and password to login
            </p>
          </div>
          <Form name="normal_login" className="login-form" layout="vertical" onFinish={onFinish} form={form}>
            <Form.Item name="email" label="Email" rules={[{
            required: true,
            message: 'Please input your Email!'
          }]}>
              <Input prefix={<MailOutlined className="site-form-item-icon" />} placeholder="Email" />
            </Form.Item>
            <Form.Item name="password" label="Password" rules={[{
            required: true,
            message: 'Please input your Password!'
          }]}>
              <Input.Password />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" className="login-form-button" block loading={loading}>
                Login
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>;
}
_s(LandingPage, "lkLlPx9UeyqpCNPGWbBXOzq/Ibg=", false, function () {
  return [Form.useForm, useSearchParams, useNavigate];
});
_c = LandingPage;
var _c;
$RefreshReg$(_c, "LandingPage");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;